import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGa from 'react-ga';
import './App.css';
import { Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import IntroSlider from './components/IntroSlider';
import { IntroData } from './components/IntroData';
import BackToTop from "react-back-to-top-button";
import Experience from './components/Experience';
import Contact from './components/Contact';
import reactDom from 'react-dom';

function App() {

  useEffect(() => {
    ReactGa.initialize('UA-193495385-1')
    //report page view
    ReactGa.pageview('/')
  }, [])

  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' />
      </Switch>
      <BackToTop 
        showOnScrollUp
        showAt={1000}
        speed={1500}
        easing="easeInOutQuint"
      >
        <span className='backtotop'>back to top</span>
      </BackToTop>
      <HeroSection />
      <IntroSlider slides ={IntroData}/>
      <Experience />
      <Contact />
    </Router>
  );
}

export default App;
