import React from 'react';
import './Experience.css';
import { Link } from 'react-router-dom';
import { IoIosArrowDropdown } from 'react-icons/io';
import SuncorEnergyLogo from '.././images/suncorLogo.png';
import HoareLabLogo from '.././images/HoareLabLogo.png';
import LEAPLogo from '.././images/LEAPLogo.png';
import ChembioLogo from '.././images/ChembioLogo.png';
import AFLogo from '.././images/AFLogo.png';
import ArdraLogo from '.././images/ArdraLogo.png';
import ApotexLogo from '.././images/ApotexLogo.png';
import Resume from '.././images/Chloe_Wu_Resume_Updated_202201.pdf';

function Experience() {
  return (
    <div>
      <>
        
      <div className='Experience-container'>
        <div className='Experience-title'>
          Experience
        </div>
        <div className='Experience-subtitle'>
        Prefer the good old CV? Find my unformatted resume <a href={Resume} className='resume-link'
        target="_blank"> here</a>.
        </div>

      </div>
        
        <div className='experience-section'>
          
          {/* EXPERIENCE : ARDRA */}
          <div className='row experience-section-row'>
            <div className="col experience-section-text-wrapper-styletwo">
              <h1 className='heading'>Ardra Inc.</h1>
              <p className='experience-section-subtitle'>May 2022 - Present</p>
              <p className='experience-section-subtitle'>Part-time Communications Intern</p>
            </div>
            <div className="col experience-section-img-wrapper-styletwo">
              <a href="//www.linkedin.com/company/ardra-bio/" target="_blank">
                  <img src={ArdraLogo} alt='Ardra Logo' className="experience-section-img ardralogo"/>
              </a>
            </div>
          </div>
          {/* EXPERIENCE : APOTEX */}
          <div className='row experience-section-row'>
            <div className="col experience-section-img-wrapper-styleone">
              <a href="//https://www.apotex.com/ca/en/" target="_blank">
                  <img src={ApotexLogo} alt='Apotex Logo' className="experience-section-img apotexlogo"/>
              </a>
            </div>
            <div className="col experience-section-text-wrapper-styleone">
              <h1 className='heading'>Apotex Inc.</h1>
              <p className='experience-section-subtitle'>May 2022 - May 2023</p>
              <p className='experience-section-subtitle'>Supply Chain Market Products Coordinator</p>
            </div>
          </div>
          {/* EXPERIENCE : THESIS */}
          <div className='row experience-section-row'>
            <div className="col experience-section-img-wrapper-styleone">
              <a href="//www.hoarelab.com/" target="_blank">
                  <img src={HoareLabLogo} alt='Hoare Lab Logo' className="experience-section-img hoarelablogo"/>
              </a>
            </div>
            <div className="col experience-section-text-wrapper-styleone">
              <h1 className='heading'>Hoare Laboratory for Engineered Smart Materials</h1>
              <p className='experience-section-subtitle'>September 2021 - April 2022</p>
              <p className='experience-section-subtitle'>Senior Thesis Student</p>
            </div>
          </div>
          {/* EXPERIENCE : SUNCOR */}
          <div className="row experience-section-row">
            <div className="col experience-section-text-wrapper-styletwo">
              <h1 className='heading'>Suncor Energy</h1>
              <p className='experience-section-subtitle'>January 2021 - August 2021</p>
              <p className='experience-section-subtitle'>Agroscience Laboratory Research Assistant</p>
            </div>
            <div className="col experience-section-img-wrapper-styletwo">
              <img src={SuncorEnergyLogo} alt='Suncor Energy Logo' className="experience-section-img suncorlogo"/>
            </div>
          </div>

          {/* EXPERIENCE : LEAP */}
          <div className='row experience-section-row'>
            <div className="col experience-section-img-wrapper-styleone">
              <a href="//www.leapcanada.org" target="_blank">
                <img src={LEAPLogo} alt='LEAP Logo' className="experience-section-img leaplogo"/>
              </a>
            </div>
            <div className="col experience-section-text-wrapper-styleone">
              <h1 className='heading'>LEAP Canada</h1>
              <p className='experience-section-subtitle'>May 2021 - Present</p>
              <p className='experience-section-subtitle'>Marketing Advisor, Board of Directors: Marketing &#38; Finance <br></br> <br></br> Sept '20 - Apr '21 | Social Media Director<br></br> May '20 - Aug '20 | Graphic Designer</p>
            </div>
          </div>

          {/* EXPERIENCE : CHEMBIO */}
          <div className="row experience-section-row">
            <div className="col experience-section-text-wrapper-styletwo">
              <h1 className='heading'>McMaster University</h1>
              <p className='experience-section-subtitle'>September 2020 - April 2022 <br/> Chemistry Teaching Assistant</p>
              <p className='experience-section-subtitle'><br/> October 2021 - Present <br/> Chemistry &#38; Chemical Biology <br/> Co-op Ambassador</p>
              <p className='experience-section-subtitle'><br/> May '20 - Aug '20 <br/> Harrison Lab Web Developer</p>
            </div>
            <div className="col experience-section-img-wrapper-styletwo">
              <a href="//https://chemistry.mcmaster.ca/" target="_blank">
                <img src={ChembioLogo} alt='McMaster Department of Chemistry and Chemical Biology Logo' className="experience-section-img Chembiologo"/>
              </a>
            </div>
          </div>

        {/* EXPERIENCE : AF */}
        <div className='row experience-section-row'>
            <div className="col experience-section-img-wrapper-styleone">
              <a href='https://www.instagram.com/mcmasterasianfocus' target='_blank'>
                <img src={AFLogo} alt='Asian Federation of Charitable University Students Logo' className="experience-section-img AFlogo"/>
              </a>
            </div>
            <div className="col experience-section-text-wrapper-styleone">
              <h1 className='heading'>Asian Federation of Charitable University Students</h1>
              <p className='experience-section-subtitle'>May 2022 - April 2023</p>
              <p className='experience-section-subtitle'>Senior Advisor - Special Projects Lead <br></br><br></br> May '21 - Apr '22 | President <br></br> May '20 - Apr '21 | Vice President Administration <br></br> May '19 - Apr '20 | Graphic Designer <br/> May '18 - Apr '19 | Junior Representative</p>
          </div>
        </div>

      </div>

      <div className='experience-down-arrow' id='contact'>
        <IoIosArrowDropdown />
      </div>

    </>

    </div>
  )
}

export default Experience
