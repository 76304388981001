import React from 'react';
import './Contact.css';
import { Link } from 'react-scroll';

function Contact() {
  return (
    <div>
      <div className='contact-box'>
        <h1 className='contact-title'>Get in touch with me!</h1>
        <p className='contact-desc'>Working on a project or want to chat about my experiences? <br/><br/> Email me at <a href='mailto:wuh104@mcmaster.ca' className='contact-link'> wuh104@mcmaster.ca</a> <br/> Connect with me on <a href='https://www.linkedin.com/in/chloew96/' className='contact-link'> LinkedIn</a></p>

        

      </div>
      
      {/* footer */}

      <Link to="top" spy={true} smooth={true} className='footer-link'> back to top </Link>
    </div>
  )
}

export default Contact
